"use client"

import { createContext } from "react"
import {
  TYPESENSE_APIKEY_IN_STORAGE,
  TYPESENSE_PARTY_APIKEY_IN_STORAGE,
  useTypesenseApiKey,
} from "./useUiTypesenseClient"

export interface TypesenseProviderProps {
  typesenseHost: string
  typesenseApiKeyMutation: string
  typesensePartyApiKeyMutation: string
  children: React.ReactNode
}

export interface TypesenseContextProps {
  typesenseHost: string
  typesenseApiKeyMutation: string
  typesensePartyApiKeyMutation: string
  apiKey?: string
  partyApiKey?: string
}

export const TypesenseContext = createContext<TypesenseContextProps>({
  typesenseHost: "",
  typesenseApiKeyMutation: "",
  typesensePartyApiKeyMutation: "",
})

export const TypesenseProvider = (props: TypesenseProviderProps) => {
  if (!props.typesenseApiKeyMutation || !props.typesensePartyApiKeyMutation) {
    console.warn(
      "for typesense, recommend to config its typesenseApiKeyMutation and typesensePartyApiKeyMutation",
    )
  }

  const { apiKey } = useTypesenseApiKey({
    mutation: props.typesenseApiKeyMutation,
    localStorageKey: TYPESENSE_APIKEY_IN_STORAGE,
  })

  const { apiKey: partyKey } = useTypesenseApiKey({
    mutation: props.typesensePartyApiKeyMutation,
    localStorageKey: TYPESENSE_PARTY_APIKEY_IN_STORAGE,
  })

  return (
    <TypesenseContext.Provider
      value={{
        typesenseHost: props.typesenseHost,
        typesenseApiKeyMutation: props.typesenseApiKeyMutation || "",
        typesensePartyApiKeyMutation: props.typesensePartyApiKeyMutation || "",
        apiKey: apiKey,
        partyApiKey: partyKey,
      }}
    >
      {props.children}
    </TypesenseContext.Provider>
  )
}
