"use client"

import { useCallback, useContext, useEffect, useState } from "react"
import { getCollectionDefaultSettings } from "./CollectionConfig"
import { TypesenseContext } from "./TypesenseProvider"
import { useTypesenseClient } from "./useUiTypesenseClient"

export interface Document {
  id: string
  label: string
}

export type TypesenseSearchProps = {
  collectionName: string
  valueAttribute?: string
  displayAttr?: string
  value: string
  customOption?: (item: object) => string
  useDefaultCollectionAttributes?: boolean
}

export const useTypesenseSearch = (props: TypesenseSearchProps) => {
  let searchProps = { ...props }
  if (props.useDefaultCollectionAttributes) {
    searchProps = {
      ...getCollectionDefaultSettings(props.collectionName),
      ...searchProps,
    }
  }
  const context = useContext(TypesenseContext)

  const [displayValue, setDisplayValue] = useState("")
  const [document, setDocument] = useState<object>()

  const searchParams = {
    query_by: searchProps.valueAttribute,
    query_by_weights: "1",
    typoTokensThreshold: 0,
    page: 50,
  }

  // check api key
  const { apiKey } = useTypesenseClient({
    searchParams: searchParams,
  })

  const createQueryString = useCallback(
    (name: string, value: string) => {
      const params = new URLSearchParams(searchParams.toString())
      params.set(name, value)

      return params.toString()
    },
    [searchParams],
  )

  const displayValueSelector = useCallback(
    (document?: object) => {
      if (!document) {
        return ""
      }
      if (searchProps.customOption) {
        return searchProps.customOption(document)
      }
      return document[(searchProps.displayAttr || "") as keyof object]
    },
    [searchProps.customOption, searchProps.displayAttr],
  )

  const searchData = useCallback(
    async (collectionName: string) => {
      if (!apiKey) return []
      try {
        // TODO interim code, use typesense package to call Typesense API
        const response = await fetch(
          `${context.typesenseHost}/search/collections/${collectionName}/documents/search?${createQueryString("q", (searchProps.value || "").toString())}&${createQueryString("query_by", searchParams.query_by || "*")}&${createQueryString("per_page", searchParams.page.toString())}`,
          {
            headers: {
              "X-Typesense-Api-Key": apiKey,
            },
          },
        )
        const data = await response.json()
        let selectDoc: object | undefined = undefined
        if (!data?.hits || data.hits.length === 0) {
          return selectDoc
        } else {
          data.hits.map((hit: { document: object }) => {
            const _document: object = hit.document as object
            const docValue =
              _document[searchProps.valueAttribute as keyof object]
            if (searchProps.value === docValue) {
              selectDoc = _document
            }
          })
          const labels = displayValueSelector(selectDoc)
          setDisplayValue(labels)
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          setDocument(selectDoc)
          return selectDoc
        }
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message)
        }

        console.error("There is an error when retrieving data")
        return []
      }
    },
    [apiKey, searchProps.value],
  )

  useEffect(() => {
    searchData(searchProps.collectionName)
  }, [searchProps.collectionName, searchData])

  return { displayValue, document }
}
