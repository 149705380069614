"use client"

import { useContext, useEffect, useState } from "react"
import TypesenseInstantSearchAdapter, {
  TypesenseInstantsearchAdapterOptions,
} from "typesense-instantsearch-adapter"
import { useMutation } from "urql"
import { SearchFieldId } from "./Constants"
import { TypesenseContext } from "./TypesenseProvider"

export interface useUiTypesenseClientProps {
  searchParams?: object
  collectionId?: SearchFieldId
}

interface TypesenseHookReturn {
  adapter?: TypesenseInstantSearchAdapter
  adapterProps?: TypesenseInstantsearchAdapterOptions
  apiKey: string
}

const PermissionSpecificCollections = [
  SearchFieldId.ExportCertificate,
  SearchFieldId.Product,
]

export const TYPESENSE_APIKEY_IN_STORAGE = "typesense-apiKey"
export const TYPESENSE_PARTY_APIKEY_IN_STORAGE = "typesense-party-apiKey"

export const clearApiKey = () => {
  localStorage.removeItem(TYPESENSE_APIKEY_IN_STORAGE)
  localStorage.removeItem(TYPESENSE_PARTY_APIKEY_IN_STORAGE)
}

export function useTypesenseApiKey({
  mutation,
  localStorageKey,
}: {
  mutation: string
  localStorageKey: string
}) {
  const [apiKey, setApiKey] = useState("")
  const [newSearchApiKeyResult, updateNewSearchApiKeyResult] =
    useMutation(mutation)

  useEffect(() => {
    const apiKeyItem = localStorage.getItem(localStorageKey)
    if (apiKeyItem) {
      const apiKeyObject: {
        apiKey: string
        expires: string
      } = JSON.parse(apiKeyItem)

      if (apiKeyObject && apiKeyObject.expires) {
        if (new Date(apiKeyObject.expires).getTime() < new Date().getTime()) {
          localStorage.removeItem(localStorageKey)
          updateNewSearchApiKeyResult()
          return
        }

        setApiKey(apiKeyObject.apiKey)
      } else {
        localStorage.removeItem(localStorageKey)
        updateNewSearchApiKeyResult()
      }
    } else {
      updateNewSearchApiKeyResult()
    }
  }, [])

  useEffect(() => {
    if (newSearchApiKeyResult?.error) {
      console.error(
        `retrieving typesense key for ${localStorageKey} failed:`,
        newSearchApiKeyResult?.error,
      )
      return
    }

    if (newSearchApiKeyResult && newSearchApiKeyResult.data) {
      const key =
        newSearchApiKeyResult.data?.newSearchApiKey?.apiKey ||
        newSearchApiKeyResult.data?.newPartyApiKey?.apiKey
      const expires =
        newSearchApiKeyResult.data?.newSearchApiKey?.expires ||
        newSearchApiKeyResult.data?.newPartyApiKey?.expires
      localStorage.setItem(
        localStorageKey,
        JSON.stringify({
          apiKey: key,
          expires: expires,
        }),
      )

      setApiKey(key)
    }
  }, [newSearchApiKeyResult.fetching])

  return { apiKey }
}

export function useTypesenseClient(
  props: useUiTypesenseClientProps,
): TypesenseHookReturn {
  const [adapter, setAdapter] = useState<TypesenseInstantSearchAdapter>()
  const [adapterProps, setAdapterProps] =
    useState<TypesenseInstantsearchAdapterOptions>()

  const { typesenseHost, apiKey, partyApiKey } = useContext(TypesenseContext)

  const isPermissionCollection =
    props.collectionId &&
    PermissionSpecificCollections.includes(props.collectionId)

  const typesenseKey = isPermissionCollection ? partyApiKey : apiKey

  useEffect(() => {
    if (!typesenseKey) {
      return
    }
    const typesenseAdapter = new TypesenseInstantSearchAdapter({
      server: {
        apiKey: typesenseKey,
        sendApiKeyAsQueryParam: false,
        nodes: [
          {
            url: `${typesenseHost}/search`,
          },
        ],
      },
      additionalSearchParameters: props.searchParams || {},
    })
    setAdapter(typesenseAdapter)
    const options: TypesenseInstantsearchAdapterOptions = {
      server: {
        apiKey: typesenseKey,
        sendApiKeyAsQueryParam: false,
        nodes: [
          {
            url: `${typesenseHost}/search`,
          },
        ],
      },
      additionalSearchParameters: props.searchParams || {},
    }
    setAdapterProps(options)
  }, [typesenseKey])

  return {
    adapter,
    adapterProps: adapterProps,
    apiKey: typesenseKey || "",
  }
}
